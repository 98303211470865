/**
 * @public
 */
export enum AttachmentType {
    Region,
    BoundingBox,
    Mesh,
    LinkedMesh,
    Path,
    Point,
    Clipping,
}
